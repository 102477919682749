import { environment as rivalEnvironment } from './environment.rival';

export const environment = {
  ...rivalEnvironment,

  rivalName: "alwaysvegas",
  casinoId: 62,
  GA_TRACKING: "G-K0DMP7QZ3B",

  rivalChatGroupName: "Always Vegas",
    prettyName: "Always Vegas",
  contactEmail: "support@alwaysvegas.eu",
  docsEmail: "documents@alwaysvegas.email",

  publicPromotions: [
    {id: 'PARADISE8_1', name: "deposit", state: "eligible", details: "welcome", imgPath: "assets/brands/alwaysvegas/promo.png"},
    {id: 2, name: "cashbackInsurance", state: "eligible", details: "welcome", imgPath: "assets/brands/alwaysvegas/promo.png"},
    {id: 'PARADISE8_3', name: "nextDayCashback", state: "eligible", details: "weekly", imgPath: "assets/brands/alwaysvegas/promo.png"},
    {id: 'PARADISE8_4', name: "loyalty", state: "eligible", details: "weekly", imgPath: "assets/brands/alwaysvegas/promo.png"}
  ],

  //SEO
  metaDescription: "Online Casino Always Vegas, including slots, blackjack, and live dealer options. Secure gambling with real cash welcome bonuses, no deposit bonus and free spins on premium slots. Bitcoin, crypto, visa, American express, skrill payments are accepted. ",
  logoName: "always-vegas-online-casino-logo.png",
  logoAltText: "Always Vegas Casino online logo with stylish silver script font evoking classic Las Vegas casino vibes popular for slots table games and casino promotions."

};


